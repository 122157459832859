import React from 'react';
import './App.css';
import LoginPage from "./pages/login";

const App = () => (
    <div className="App" style={{minHeight: '100vh', overflowY: 'hidden'}}>
      <LoginPage />
    </div>
);

export default App;