import {Button, Carousel, Checkbox, Col, Form, Input, Row, Tooltip, notification } from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import React from "react";
import MarsImage from "../assets/img/mars.jpg";

// const contentStyle: React.CSSProperties = {
//     height: '100vh',
//     color: '#fff',
//     lineHeight: '100vh',
//     textAlign: 'center',
//     // background: '#364d79',
//     backgroundImage: galaxyImage,
// };

const LoginPage: React.FC = () => {

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onClick = () => {
        notification.error({
            message: 'Login Forbidden',
            description:
                'Please use the company\'s internal network to log in, or connect to VPN to use',
        });
    }
    return (
        <div>
            <Row style={{maxHeight: '100vh'}}>
                <Col span={12}>
                    <Carousel effect="fade" style={{maxHeight: '100vh'}}>
                        <div>
                            <img src={MarsImage} style={{height: '100vh'}}/>
                            {/*<h3 style={contentStyle}>Tsingland Dashboard Login</h3>*/}
                        </div>
                    </Carousel>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <h1 style={{marginTop: "20vh", fontFamily: "Pirulen"}}>TSINGLAND CAPITAL</h1>
                        </Col>
                        <Col span={24}>
                            <code style={{marginTop: "20vh"}}>Make Investment More Efficient, Make Risks More Transparent.</code>
                        </Col>
                        <Col span={18} offset={6}>
                            <Form
                                style={{marginTop: "10vh"}}
                                name="login"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="true"
                            >

                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please Input Username!' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please Input Password!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" style={{float: "left"}}>
                                        <Checkbox>Remember Me</Checkbox>
                                    </Form.Item>

                                    <Tooltip title="please connect the administrator to reset your password" color={'cyan'} style={{float: "right"}}>
                                        <Button type="link" style={{float: "right"}}>Forgot Password</Button>
                                    </Tooltip>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" block onClick={onClick}>
                                        Log In
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>



                </Col>
            </Row>
        </div>
    )
};

export default LoginPage;